 /* Style de l'accordéon principal */
.accordion {
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
  width: "100%";
  }
  
  /* Style de l'en-tête de l'accordéon */
  .accordion-header {
    width: "100%";
  }
  
  
  /* Style de l'icône (le signe + ou -) */
  .icon {
    font-size: 18px;
    margin-left: 10px;
  }
  
  /* Style de l'accordéon ouvert */
  .accordion.open .accordion-header {
     background-color: #e0e0e0;
  }
  
  /* Style du contenu de l'accordéon */
  .accordion-content {
    background-color: #f2f2f2;
    margin-left: 20px;
    margin-bottom: 20px;
    border-left: 1px solid #ccc;
    width: "100%";
  }

  /* Style de base pour l'accordéon */
  .accordion {
    overflow: hidden;
  }
  
  /* En-tête de l'accordéon */
  .accordion-header {
    background-color: #f2f2f2;
    padding: 5px;
    cursor: pointer;
    display: flex;
    width: "100%";
  }
  
  /* Icône pour indiquer l'état ouvert/fermé */
  .accordion .icon {
    font-size: 15px;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
    transition: transform 0.3s;
  }
  
  .accordion .icon.open {
    transform: rotate(45deg);
  }
  
  
  .accordion li {
    background-color: #f2f2f2;
    padding: 10px;
    max-width: 800px;
  }