body {
  margin: 0;
  padding: 0;
}

.homepage {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  background-image: url('https://s3.fr-par.scw.cloud/static.tcp/TCP_Fond.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  filter: brightness(0.5);
}



.background-dark {
  width: 100%;
  height: 100%;
  background-color: #333
}
 

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

h1 {
  font-size: 3rem;
}
